// we're just overriding bootstrap's tooltip to match unity styles
.tooltip .tooltip-inner,
.unity-tooltip {
    width: max-content;
    min-width: 2rem;
    max-width: 25rem;
    max-height: 70vh;
    overflow: visible;
    font: var(--unity-font-body-medium);
    background-color: var(--unity-color-surface-dark-s10);
    border-radius: var(--unity-radius-base);
    box-shadow: var(--unity-elevation-4);
}

.tooltip .tooltip-inner,
.popover-header,
.popover-body {
    padding: var(--unity-spacing-04) var(--unity-spacing-06);
}

.tooltip,
.unity-tooltip {
    &.bs-tooltip-top .tooltip-arrow::before,
    &.bs-popover-top .popover-arrow::after {
        border-top-color: var(--unity-color-surface-dark-s10);
    }
    &.bs-tooltip-bottom .tooltip-arrow::before,
    &.bs-popover-bottom .popover-arrow::after {
        border-bottom-color: var(--unity-color-surface-dark-s10);
    }
    &.bs-tooltip-right .tooltip-arrow::before,
    &.bs-tooltip-end .tooltip-arrow::before,
    &.bs-popover-right .popover-arrow::after,
    &.bs-popover-end .popover-arrow::after {
        border-right-color: var(--unity-color-surface-dark-s10);
    }
    &.bs-tooltip-left .tooltip-arrow::before,
    &.bs-tooltip-start .tooltip-arrow::before,
    &.bs-popover-left .popover-arrow::after,
    &.bs-popover-start .popover-arrow::after {
        border-left-color: var(--unity-color-surface-dark-s10);
    }
}

.unity-tooltip {
    .popover-header,
    .popover-body {
        padding: var(--unity-spacing-04) var(--unity-spacing-06);
        color: var(--unity-color-font-primary-on_dark);
    }

    .popover-header {
        font: var(--unity-font-body-small);
        border-bottom: 1px solid var(--unity-color-border-divider-default-on_dark);
        background-color: transparent;
    }

    &.bg-white {
        background-color: var(--unity-color-gray-white);

        &.bs-tooltip-top .tooltip-arrow::before,
        &.bs-popover-top .popover-arrow::after {
            border-top-color: var(--unity-color-gray-white);
        }
        &.bs-tooltip-bottom .tooltip-arrow::before,
        &.bs-popover-bottom .popover-arrow::after {
            border-bottom-color: var(--unity-color-gray-white);
        }
        &.bs-tooltip-right .tooltip-arrow::before,
        &.bs-popover-right .popover-arrow::after {
            border-right-color: var(--unity-color-gray-white);
        }
        &.bs-tooltip-left .tooltip-arrow::before,
        &.bs-popover-left .popover-arrow::after {
            border-left-color: var(--unity-color-gray-white);
        }

        .popover-header,
        .popover-body {
            color: var(--unity-color-font-primary-on_light);
        }

        .popover-header {
            border-bottom: 1px solid var(--unity-color-border-divider-default-on_light);
        }
    }
}
