
/*
    This file contains only Bootstrap Sass variable overrides.
    Nothing in this file should concern specific theming; strictly basic stuff.
    This file needs to be loaded BEFORE Bootstrap along with the theme file.
*/

// need to (p)reinit this stuff first
$gray-100: #f9f9f9;
$gray-200: #ececec;
$gray-300: #e2e2e2;
$gray-400: #d4d4d4;
$gray-500: #b5b5b5;
$gray-600: #757575;
$gray-700: #505050;
$gray-800: #3a3a3a;
$gray-900: #252525;

$body-color: $gray-800;

// Grid

$grid-breakpoints: (
    xs: 0,
    sm: 480px,
    md: 960px,
    lg: 1440px,
    xl: 1710px,
    xx: 2220px
);

$grid-gutter-width: 1.5rem;


// Sizing

$sizes: (
    10: 10%,
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto
);


// Links

$link-color: $color-action;
$link-hover-decoration: none;


// Transition

$transition-base: all .25s ease-in-out;


// Fonts

$font-family-base: 'Source Sans Pro', Helvetica, sans-serif;

$font-size-base: 0.875rem;
$font-size-lg: 1rem;
$font-size-sm: .8125rem;
$font-size-xs: .75rem;

$h1-font-size: 1.66rem;
$h2-font-size: 1.33rem;
$h3-font-size: 1.125rem;
$h4-font-size: 1rem;

$font-weight-normal: 400;
$font-weight-bold: 600;
$font-weight-xbold: 700;
$headings-font-weight: $font-weight-bold;

$text-muted: $gray-500;


// Forms

$input-btn-line-height: 1.5;
$input-btn-padding-y: 0.25rem;
$input-btn-padding-y-lg: .5rem;
$input-color: #000;
$input-border-color: rgba(0,0,0,0.25);
$input-placeholder-color: $gray-500;


// Components

$border-radius: var(--unity-radius-base);
$border-radius-lg: 0.4rem;
$border-radius-sm: 0.15rem;


// Cards

$card-spacer-x: 0.75rem !default;
$card-spacer-y: 0.5rem !default;
$card-bg: var(--unity-color-surface-light-base);
$card-border-color: var(--unity-color-border-container-default);
$card-box-shadow: var(--unity-elevation-1);


// Modals

$modal-sm: 20rem;
$modal-md: 32rem;
$modal-lg: 50rem;
$modal-xl: 72rem;


// Tooltips

$tooltip-opacity: 1;


:root {
    --gray-100: #{$gray-100};
    --gray-200: #{$gray-200};
    --gray-300: #{$gray-300};
    --gray-400: #{$gray-400};
    --gray-500: #{$gray-500};
    --gray-600: #{$gray-600};
    --gray-700: #{$gray-700};
    --gray-800: #{$gray-800};
    --gray-900: #{$gray-900};
    --body-color: #{$body-color};
    --grid-breakpoint-xs: #{map-get($grid-breakpoints,xs)};
    --grid-breakpoint-sm: #{map-get($grid-breakpoints,sm)};
    --grid-breakpoint-md: #{map-get($grid-breakpoints,md)};
    --grid-breakpoint-lg: #{map-get($grid-breakpoints,lg)};
    --grid-breakpoint-xl: #{map-get($grid-breakpoints,xl)};
    --grid-breakpoint-xx: #{map-get($grid-breakpoints,xx)};
    --grid-gutter-width: #{$grid-gutter-width};
    --link-color: #{$link-color};
    --link-hover-decoration: #{$link-hover-decoration};
    --transition-base: #{$transition-base};
    --font-family-base: #{$font-family-base};
    --font-size-base: #{$font-size-base};
    --font-size-lg: #{$font-size-lg};
    --font-size-sm: #{$font-size-sm};
    --font-size-xs: #{$font-size-xs};
    --h1-font-size: #{$h1-font-size};
    --h2-font-size: #{$h2-font-size};
    --h3-font-size: #{$h3-font-size};
    --h4-font-size: #{$h4-font-size};
    --font-weight-base: #{$font-weight-normal};
    --font-weight-normal: #{$font-weight-normal};
    --font-weight-bold: #{$font-weight-bold};
    --font-weight-xbold: #{$font-weight-xbold};
    --headings-font-weight: #{$headings-font-weight};
    --text-muted: #{$text-muted};
    --input-btn-padding-y-lg: #{$input-btn-padding-y-lg};
    --input-color: #{$input-color};
    --input-border-color: #{$input-border-color};
    --input-placeholder-color: #{$input-placeholder-color};
    --border-radius: #{$border-radius};
    --border-radius-lg: #{$border-radius-lg};
    --border-radius-sm: #{$border-radius-sm};
    --card-spacer-x: #{$card-spacer-x};
    --card-spacer-y: #{$card-spacer-y};
    --card-box-shadow: #{$card-box-shadow};
}
