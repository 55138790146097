﻿
// Bootstrap overrides
$primary: #525252; /* this results in #393939, the Unity standard */
$secondary: #888;
$green: #5b5;

// custom colors
$color-primary-darkish: darken( $primary, 5% );
$color-primary-dark: darken( $primary, 10% );
$color-primary-darker: darken( $primary, 15% );
$color-primary-light: lighten( $primary, 5% );
$color-primary-lighter: lighten( $primary, 10% );
$color-primary-lightest: lighten( desaturate( $primary, 25% ), 70% );
$color-primary-fade: desaturate( $primary, 35% );
$color-action: #4488cc; // revision mode background color
$color-active: #9977bb;

:root {
    --color-primary-darkish: #{$color-primary-darkish};
    --color-primary-dark: #{$color-primary-dark};
    --color-primary-darker: #{$color-primary-darker};
    --color-primary-light: #{$color-primary-light};
    --color-primary-lighter: #{$color-primary-lighter};
    --color-primary-lightest: #{$color-primary-lightest};
    --color-primary-fade: #{$color-primary-fade};
    --color-action: #{$color-action};
    --color-active: #{$color-active};
}
