@keyframes loader {
    0% {
        transform: rotate(0deg);
        border-top-color: transparent;
        border-right-color: transparent;
        border-left-color: transparent;
        border-bottom-color: $gray-100;
    }
    25% {
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-top-color: transparent;
        border-left-color: $gray-100;
    }
    50% {
        border-bottom-color: transparent;
        border-right-color: transparent;
        border-left-color: transparent;
        border-top-color: $gray-100;
    }
    75% {
        border-left-color: transparent;
        border-bottom-color: transparent;
        border-top-color: transparent;
        border-right-color: $gray-100;
    }
    100% {
        transform: rotate(360deg);
        border-top-color: transparent;
        border-right-color: transparent;
        border-left-color: transparent;
        border-bottom-color: $gray-100;
    }
}

@keyframes spinCW {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes spinCCW {
    0% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

/*--  Bootstrap overrides  --*/

.tooltip-shown {
    pointer-events: none;
}

.text-muted {
    color: inherit !important;
    opacity: 0.6;
}

.row {
    > label {
        align-self: center;
        margin: 0;
    }
}

.modal {
    &.show {
        align-items: center;
    }

    label {
        margin-bottom: calc($width_gutter_small/2);
    }

    &-content {
        max-height: 90vh;

        &,
        > *:not(.modal-header):not(.modal-body):not(.modal-footer) {
            overflow: auto;
        }

        > *:not(.modal-header):not(.modal-body):not(.modal-footer) {
            display: flex;
            flex-direction: column;
        }
    }

    &-dialog {
        display: flex;
        align-items: center;
        margin: 0 auto;
        height: 100%;
    }

    &-wide {
        width: 90vw;
        max-width: 100rem;
    }

    &-header {
        justify-content: space-between;
        
        h3 {
            margin: 0;
        }

        [unity-button] {
            margin: 0;
        }
    }

    &-body {
        padding-right: calc(1rem - var(--width_scrollbar));
        overflow: hidden;
        overflow-y: scroll;
    }

    &-footer {
        flex-wrap: nowrap;
        justify-content: flex-end;
    }
}

.form-check-input:checked[type=checkbox] {
    border-color: var(--color-active);
    background-color: var(--color-active);
}
.form-check .form-check-input:disabled:checked {
    border-color: var(--unity-color-gray-060);
    background-color: var(--unity-color-gray-060);
}
.form-check .form-check-input:checked[type=radio]  {
    background: white;
    border: 0.3rem solid var(--color-active);
}
.form-check .form-check-input[type=radio] {
    border-radius: 999px;
}

.dropdown-toggle {
    cursor: pointer;
}

.bs-popover-left-top {
    border-top-right-radius: 0;
}
.popover .work_pane--stream {
    max-height: 40vh;
}
.popover-fit_content {
    max-width: 100%;
}

.tooltip-inner {
    text-align: left;
}

ngb-modal-window {
    &.no-bg .modal-content {
        display: contents;
    }
}

tag {
    height: 1.5rem !important;
    line-height: 1.8 !important;

    svg {
        height: 1rem !important;
        vertical-align: middle !important;
    }
}

.ng2-tag-input {
    padding: 0.125rem 0 !important;
    min-height: 1.5rem !important;
}

.ng2-tag-input__text-input {
    height: 1.5rem !important;
    font-family: inherit !important;
}

/*--  Kendo popover styles  --*/

security-role-grid-button .popup {
    width: 85px;
}

.center-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

/*--  Kendo overrides  --*/

.k-button:not([unity-button]),
.k-list-container .k-nodata .k-button:not([unity-button]),
.k-grouping-header .k-group-indicator,
.k-spreadsheet-filter,
.k-pivot-toolbar .k-button:not([unity-button]),
.k-fieldselector .k-list li.k-item,
.k-colorpicker .k-picker-wrap,
.k-datepicker .k-select,
.k-timepicker .k-select,
.k-datetimepicker .k-select,
.k-dateinput .k-select,
.k-editor-toolbar a.k-tool,
.k-numerictextbox .k-select,
.k-switch-wrapper,
.km-switch-wrapper,
.k-switch-handle,
.km-switch-handle,
.k-handle,
.k-map .k-navigator,
.k-gantt-views li,
.k-task-single,
.k-scheduler-navigation .k-nav-today,
.k-scheduler-navigation .k-nav-prev,
.k-scheduler-navigation .k-nav-next,
.k-scheduler-views li,
.k-scheduler-footer li,
.k-button:not([unity-button]):hover,
.k-button:not([unity-button]).k-state-hover,
.k-button:not([unity-button]).k-flat:hover,
.k-button:not([unity-button]).k-flat.k-state-hover,
.k-dropdown > :hover,
.k-dropdown .k-state-hover,
.k-grid .k-button:not([unity-button]):hover,
.k-spreadsheet-filter:hover,
.k-action-buttons .k-button:not([unity-button]):hover,
.k-dialog-buttongroup .k-button:not([unity-button]):hover,
.k-pivot-toolbar .k-button:not([unity-button]):hover,
.k-pivot-toolbar .k-button:not([unity-button]).k-state-hover,
.k-fieldselector .k-list li.k-item:hover,
.k-fieldselector .k-list li.k-item.k-state-hover,
.k-colorpicker .k-state-hover,
.k-combobox > :hover .k-select,
.k-combobox .k-state-hover .k-select,
.k-datepicker .k-state-hover .k-select,
.k-timepicker .k-state-hover .k-select,
.k-datetimepicker .k-state-hover .k-select,
.k-dateinput .k-select:hover,
.k-editor-toolbar a.k-tool:hover,
.k-editor-toolbar a.k-tool.k-state-hover,
.k-numerictextbox .k-state-hover .k-select,
.k-handle:hover,
.k-gantt-views li.k-state-hover,
.k-scheduler-navigation .k-nav-today.k-state-hover,
.k-scheduler-navigation .k-nav-prev.k-state-hover,
.k-scheduler-navigation .k-nav-next.k-state-hover,
.k-scheduler-views li.k-state-hover,
.k-scheduler-footer li.k-state-hover,
.k-ghost-splitbar {
    color: #000;
    background-color: transparent;
    border-color: $gray-500;
}

.k-card {
    box-shadow: var(--unity-elevation-1);

    &-header {
        border-color: transparent;
        color: var(--unity-color-font-primary-on_light);
        background-color: transparent;
        padding: 0;
        border-width: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    &-title {
        font: var(--unity-font-headline-small);
    }

    &-body {
        padding: 0;
    }
}

.k-tilelayout {
    gap: 1rem !important;
    background-color: transparent;

    .k-card {
        margin: 0;
        padding: 0;
        background: transparent;
        border: 0;
        border-radius: 0;
        box-shadow: none;
        overflow: visible;
    }
}

.k-cursor-ew-resize {
    top: 0;
    cursor: ew-resize;
}
.k-cursor-ns-resize {
    left: 0;
    cursor: ns-resize;
}
.k-cursor-nwse-resize {
    cursor: nwse-resize;
}
.k-cursor-grab {
    cursor: grab;
}

.k-grid {
    color: var(--unity-color-font-tertiary-on_light);
    border-color: var(--unity-color-border-container-default);

    th {
        padding-top: calc($width_gutter_small/2);
        padding-bottom: calc($width_gutter_small/2);

        &.k-hierarchy-cell:first-child + th[aria-colindex="1"],
        &:has(.form-check) + th[aria-colindex="1"],
        &:has(.k-checkbox) + th[aria-colindex="1"] {
            border-inline-start-color: transparent;
        }
    }

    tr {
        &.dragging {
            box-shadow: inset 0 0 0 3px var(--color-active);
        }

        &:hover {
            background-color: transparent !important;

            .k-command-cell > * {
                visibility: visible;
            }
        }
    }

    thead {
        tr:has(+ tr) th[rowspan="1"] {
            border-bottom-color: var(--unity-color-border-divider-light-on_light) !important;
        }
    }

    tbody {
        tr {
            color: var(--unity-color-font-tertiary-on_light);

            &.k-detail-row {
                background-color: var(--unity-color-surface-light-s10);
            }

            &:hover {
                background-color: var(--unity-color-background-selection-hover) !important;
            }

            &,
            &.k-table-row {
                background-color: white;
            }

            &:first-child td {
                border-top-width: 0 !important;
            }
        }
    }

    colgroup col:last-of-type {
        min-width: 3rem;
    }

    td {
        &,
        .k-table-td {
            line-height: 1.25;
            border-color: var(--unity-color-border-divider-light-on_light);
        }

        a.k-i-collapse {
            color: var(--unity-color-brand-magenta-t10);
        }
    }

    th,
    td,
    &-edit-row td {
        padding-left: $width_gutter_small;
        padding-right: $width_gutter_small;
        box-sizing: border-box !important;
    }

    td,
    &-edit-row td {
        height: 2.5rem;
        padding-top: $width_gutter_small * 0.66;
        padding-bottom: $width_gutter_small * 0.66;
        border-width: 1px 0 0 0 !important;
        border-color: var(--unity-color-border-divider-light-on_light) !important;
    }

    td:not(.k-hierarchy-cell),
    &-edit-row td {
        a {
            color: var(--unity-color-font-link-default-on_light);
            text-decoration: underline;
        }
    }

    &-md {
        .k-table-th,
        td,
        .k-grid-td {
            padding-block: var(--unity-spacing-04) !important;
            padding-inline: var(--unity-spacing-08) !important;
        }
    }

    .thumbnail {
        padding-top: 0.5rem;
        padding-right: 0;
        padding-bottom: 0.5rem;

        svg {
            width: 1.5rem;
            height: 1.5rem;
        }
    }

    svg.unity-icon-checkmark {
        color: var(--bs-success);
    }

    .k-hierarchy-col {
        width: 2rem;
    }
    .k-hierarchy-cell,
    .k-reset {
        kendo-icon-wrapper {
            display: none;
        }

        a {
            display: block;
            width: var(--unity-spacing-08);
            height: var(--unity-spacing-08);
            -webkit-mask-position: center;
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-size: 1rem 1rem;
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: 1rem 1rem;
            background-color: var(--unity-color-icon-secondary-on_light);
        }
    }
    .k-hierarchy-cell {
        a {
            &:has(.k-svg-i-plus) {
                -webkit-mask-image: url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><title>chevron-right</title><path d="M12.982 11.957l-4.086 4.086c-0.391 0.39-0.391 1.024 0 1.414s1.024 0.39 1.414 0l4.793-4.793c0.39-0.39 0.39-1.024 0-1.414l-4.793-4.793c-0.39-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414l4.086 4.086z"></path></svg>');
                mask-image: url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><title>chevron-right</title><path d="M12.982 11.957l-4.086 4.086c-0.391 0.39-0.391 1.024 0 1.414s1.024 0.39 1.414 0l4.793-4.793c0.39-0.39 0.39-1.024 0-1.414l-4.793-4.793c-0.39-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414l4.086 4.086z"></path></svg>');
            }

            &:has(.k-svg-i-minus) {
                -webkit-mask-image: url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><title>chevron-down</title><path d="M12 13.086l-4.086-4.086c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414l4.793 4.793c0.39 0.39 1.024 0.39 1.414 0l4.793-4.793c0.39-0.39 0.39-1.024 0-1.414s-1.024-0.391-1.414 0l-4.086 4.086z"></path></svg>');
                mask-image: url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><title>chevron-down</title><path d="M12 13.086l-4.086-4.086c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414l4.793 4.793c0.39 0.39 1.024 0.39 1.414 0l4.793-4.793c0.39-0.39 0.39-1.024 0-1.414s-1.024-0.391-1.414 0l-4.086 4.086z"></path></svg>');
            }
        }
    }

    .k-reset {
        a {
            &:has(.k-svg-i-caret-alt-right) {
                -webkit-mask-image: url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><title>chevron-right</title><path d="M12.982 11.957l-4.086 4.086c-0.391 0.39-0.391 1.024 0 1.414s1.024 0.39 1.414 0l4.793-4.793c0.39-0.39 0.39-1.024 0-1.414l-4.793-4.793c-0.39-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414l4.086 4.086z"></path></svg>');
                mask-image: url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><title>chevron-right</title><path d="M12.982 11.957l-4.086 4.086c-0.391 0.39-0.391 1.024 0 1.414s1.024 0.39 1.414 0l4.793-4.793c0.39-0.39 0.39-1.024 0-1.414l-4.793-4.793c-0.39-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414l4.086 4.086z"></path></svg>');
            }

            &:has(.k-svg-i-caret-alt-down) {
                -webkit-mask-image: url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><title>chevron-down</title><path d="M12 13.086l-4.086-4.086c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414l4.793 4.793c0.39 0.39 1.024 0.39 1.414 0l4.793-4.793c0.39-0.39 0.39-1.024 0-1.414s-1.024-0.391-1.414 0l-4.086 4.086z"></path></svg>');
                mask-image: url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><title>chevron-down</title><path d="M12 13.086l-4.086-4.086c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414l4.793 4.793c0.39 0.39 1.024 0.39 1.414 0l4.793-4.793c0.39-0.39 0.39-1.024 0-1.414s-1.024-0.391-1.414 0l-4.086 4.086z"></path></svg>');
            }

            &:has(+ span) {
                margin-inline-end: 1rem;
            }
        }
    }

    .k-command-cell {
        > * {
            line-height: 0;
            border: 0;
            visibility: hidden;
        }

        > .d-flex {
            justify-content: flex-end;
        }
    }

    &-edit-command,
    &-save-command,
    &-cancel-command,
    &-remove-command {
        padding-block: 0 !important;
        padding-inline: 0 !important;

        &::after {
            content: none !important;
        }
    }

    a.k-button {
        border: 0;
        padding: 0;
    }

    &-header {
        .k-header > .k-link {
            margin: -0.33rem 0 -0.33rem -0.75rem;
            padding: 0.33rem 0 0.33rem 0.75rem;
            color: $kendo-grid-header-text;

            > .k-icon {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    &-header,
    .k-table-thead,
    .k-grouping-header,
    .k-group-cell {
        padding: 0 !important;
        font: var(--unity-font-label-medium);
        color: var(--unity-color-font-primary-on_light);
        background-color: var(--unity-color-gray-005);
        border-color: var(--unity-color-border-container-default);
    }

    .k-table-row.k-selected > td,
    .k-table-row.k-selected > .k-table-td,
    .k-table-row:has(.form-check-input:checked[type=checkbox]) {
        background-color: var(--unity-color-background-selection-selected);
    }

    col.k-sorted,
    .k-table-th.k-sorted {
        background-color: transparent;
    }

    .k-table-th {
        .k-link {
            cursor: default;

            &:has(.k-sort-icon) {
                cursor: pointer;
            }
        }

        kendo-icon-wrapper {
            visibility: hidden;
        }

        .k-sort-icon {
            display: block;
            margin-inline-start: auto !important;
            width: var(--unity-spacing-08);
            height: var(--unity-spacing-08);
            -webkit-mask-position: center;
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-size: 1rem 1rem;
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: 1rem 1rem;
            background-color: var(--unity-color-icon-secondary-on_light);

            &:not(:has(.k-svg-i-sort-desc-small)):not(:has(.k-svg-i-sort-asc-small)) {
                -webkit-mask-image: url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><title>sort</title><path d="M11.993 18.379l-4.086-4.086c-0.391-0.39-1.024-0.39-1.414 0s-0.391 1.024 0 1.414l4.793 4.793c0.39 0.39 1.024 0.39 1.414 0l4.793-4.793c0.39-0.39 0.39-1.024 0-1.414s-1.024-0.39-1.414 0l-4.086 4.086z"></path><path d="M11.993 5.414l-4.086 4.086c-0.391 0.391-1.024 0.391-1.414 0s-0.391-1.024 0-1.414l4.793-4.793c0.39-0.391 1.024-0.391 1.414 0l4.793 4.793c0.39 0.391 0.39 1.024 0 1.414s-1.024 0.391-1.414 0l-4.086-4.086z"></path></svg>');
                mask-image: url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><title>sort</title><path d="M11.993 18.379l-4.086-4.086c-0.391-0.39-1.024-0.39-1.414 0s-0.391 1.024 0 1.414l4.793 4.793c0.39 0.39 1.024 0.39 1.414 0l4.793-4.793c0.39-0.39 0.39-1.024 0-1.414s-1.024-0.39-1.414 0l-4.086 4.086z"></path><path d="M11.993 5.414l-4.086 4.086c-0.391 0.391-1.024 0.391-1.414 0s-0.391-1.024 0-1.414l4.793-4.793c0.39-0.391 1.024-0.391 1.414 0l4.793 4.793c0.39 0.391 0.39 1.024 0 1.414s-1.024 0.391-1.414 0l-4.086-4.086z"></path></svg>');
            }
        }

        &.k-sorted {
            .k-link::after {
                content: '';
                position: absolute;
                z-index: 1;
                bottom: 0.375rem;
                right: 0.75rem;
                width: 1.5rem;
                height: 1.5rem;
                background-color: var(--unity-color-background-button-active-on_light);
                border-radius: 50%;
            }

            .k-sort-icon {
                position: relative;
                background-color: var(--unity-color-brand-magenta-s20);

                &:has(.k-svg-i-sort-desc-small) {
                    -webkit-mask-image: url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><title>sort-des</title><path d="M11.993 18.379l-4.086-4.086c-0.391-0.39-1.024-0.39-1.414 0s-0.391 1.024 0 1.414l4.793 4.793c0.39 0.39 1.024 0.39 1.414 0l4.793-4.793c0.39-0.39 0.39-1.024 0-1.414s-1.024-0.39-1.414 0l-4.086 4.086z"></path></svg>');
                    mask-image: url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><title>sort-des</title><path d="M11.993 18.379l-4.086-4.086c-0.391-0.39-1.024-0.39-1.414 0s-0.391 1.024 0 1.414l4.793 4.793c0.39 0.39 1.024 0.39 1.414 0l4.793-4.793c0.39-0.39 0.39-1.024 0-1.414s-1.024-0.39-1.414 0l-4.086 4.086z"></path></svg>');

                    + .k-sort-order {
                        bottom: 0.4rem;
                    }
                }

                &:has(.k-svg-i-sort-asc-small) {
                    -webkit-mask-image: url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><title>sort-asc</title><path d="M11.993 5.414l-4.086 4.086c-0.391 0.391-1.024 0.391-1.414 0s-0.391-1.024 0-1.414l4.793-4.793c0.39-0.391 1.024-0.391 1.414 0l4.793 4.793c0.39 0.391 0.39 1.024 0 1.414s-1.024 0.391-1.414 0l-4.086-4.086z"></path></svg>');
                    mask-image: url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><title>sort-asc</title><path d="M11.993 5.414l-4.086 4.086c-0.391 0.391-1.024 0.391-1.414 0s-0.391-1.024 0-1.414l4.793-4.793c0.39-0.391 1.024-0.391 1.414 0l4.793 4.793c0.39 0.391 0.39 1.024 0 1.414s-1.024 0.391-1.414 0l-4.086-4.086z"></path></svg>');

                    + .k-sort-order {
                        bottom: -0.125rem;
                    }
                }
            }

            .k-sort-order {
                position: absolute;
                right: 1.3rem;
                color: var(--unity-color-brand-magenta-s20);
                line-height: 0;
            }
        }
    }

    .k-grouping-drop-container {
        padding-inline: var(--unity-spacing-08);
        font: var(--unity-font-label-small);
        font-style: italic;
        color: var(--unity-color-font-disabled-on_light);
    }

    .k-grouping-row td {
        font: var(--unity-font-label-medium);
    }

    &-content {
        overflow: auto;
    }

    &-clean {
        border: 0;

        thead {
            display: none;
        }

        tr {
            &:first-child td {
                border: 0;
            }
        }

        td {
            padding: 0.25em 0;
            font-size: $font-size-sm;
            border-top: 1px solid $gray-300;
        }
    }

    &-toolbar {
        margin: -1px -1px 0 -1px;
        padding-inline: 0;
        background-color: white;

        .k-button {
            padding: 0 !important;
        }
    }

    &:has(.k-command-cell) th:last-child {
        border-left: 0;
    }
}

.k-table {
    color: var(--unity-color-font-tertiary-on_light);
}

.k-detail-row td {
    border: 0;
}

.k-column-menu .k-menu:not(.k-context-menu) .k-item.k-state-selected,
.k-columnmenu-item.k-state-selected,
.k-list-container .k-button:active,
.k-list-container .k-button.k-state-active,
.k-list .k-item.k-state-selected,
.k-list-optionlabel.k-state-selected {
    background-color: var(--color-active);
}

.k-list .k-item:focus.k-state-selected,
.k-list .k-item.k-state-focused.k-state-selected,
.k-list-optionlabel:focus.k-state-selected,
.k-list-optionlabel.k-state-focused.k-state-selected {
    box-shadow: none;
}

.k-switch {
    font-size: 0.5rem;

    // Until we upgrade bootstrap, we need this for the UI
    .k-switch-track {
        border-radius: 5rem;
        border: none;
    }

    // Until we upgrade bootstrap, we need this for the UI
    .k-switch-thumb {
        border-radius: 5rem;
        border-color: $white;
        background: $white;
    }
}

.k-switch.k-disabled {
    opacity: 0.4;
}

.k-switch-on, .k-switch-on.k-disabled {
    .k-switch-track {
        background: var(--color-active);
    }
}

.k-switch-off, .k-switch-off.k-disabled {
    .k-switch-track {
        background: #888;
    }
}

.k-command-cell {
    width: max-content;
    text-align: right !important;

    > .k-button {
        margin: 0;

        + .k-button {
            margin-left: 0.75rem;
        }
    }
}

.k-dropdown .k-dropdown-wrap {
    background-color: $white !important;
    border-color: rgba(0, 0, 0, 0.25) !important;
}

.k-widget.form-control .k-input,
.form-control.k-block .k-input {
    height: auto;
}

.confirm-edit-mode {
    .k-multiselect {
        background: 0 0;
        padding: 0 calc( 0.75rem + 64px ) 0 0;
    }
}

.k-multiselect-popup--hidden {
    display: none;
}

.k-multiselect {
    border-color: $input-border-color;
    background: #fff
        url("data:image/svg+xml;charset=utf8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%0A%3Ctitle%3Echevron-down%3C%2Ftitle%3E%0A%3Cpath%20d%3D%22M12%2013.086l-4.086-4.086c-0.391-0.391-1.024-0.391-1.414%200s-0.391%201.024%200%201.414l4.793%204.793c0.39%200.39%201.024%200.39%201.414%200l4.793-4.793c0.39-0.39%200.39-1.024%200-1.414s-1.024-0.391-1.414%200l-4.086%204.086z%22%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E")
        no-repeat right 0.75rem center;
    background-size: 16px 16px;

    input::placeholder {
        color: $gray-500;
        opacity: 1;
    }

    li.k-button {
        min-height: 0;
        padding: 0.2em 0.4em;
        font-size: var(--font-size-xs);
    }

    .k-clear-value {
        display: none !important;
    }
}

.k-input {
    &::placeholder {
        color: $gray-500;
    }
}

.k-i-arrow-s {
    opacity: 0;
}

kendo-splitter {
    background-color: transparent !important;
    border: 0 !important;
}
.k-splitter-flex .k-pane {
    display: flex;
    flex-direction: column;
    transition: all var(--unity-time-default) ease-in-out;
}
.k-splitbar {
    color: $gray-400;
    background-color: transparentize($gray-300, 1);
    transition: $transition-base;

    &:hover,
    &:active {
        color: $gray-500;
        background-color: $gray-300;
    }
}

.k-grouping-header .k-group-indicator {
    color: $white;

    .k-button .k-icon {
        color: $white;
    }
}

.k-state-disabled {
    opacity: 0.4;
    cursor: not-allowed;
}

[ng2FileDrop] {
    cursor: pointer;
}

/*--  cdk virtual scroll --*/
cdk-virtual-scroll-viewport {
    flex: 1;
    overflow-y: scroll !important;
}

.cdk-virtual-scroll-content-wrapper {
    width: 100%;
}

/*--  Base / Frame / Reusables  --*/
body {
    background-color: var(--unity-semantic-color-surface-base-neutral-standard);
    overflow: hidden;
}

* {
    scrollbar-width: thin;
    scrollbar-color: rgba(155, 155, 155, 0.33) transparent;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
        width: var(--width_scrollbar);
    }

    *::-webkit-scrollbar-track {
        background: transparent;
    }

    *::-webkit-scrollbar-thumb {
        background-color: rgba(155, 155, 155, 0.33);
        border-radius: var(--width_scrollbar);
        border: calc(var(--width_scrollbar) / 4) solid transparent;
        background-clip: padding-box;
        border-left-width: calc(var(--width_scrollbar) / 3);
    }

    &:focus {
        outline: none;
    }
}
.scrollit {
    overflow: hidden;
    overflow-y: scroll;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $gray-500;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: $gray-500;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: $gray-500;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: $gray-500;
}

router-outlet {
    display: contents;
}

a {
    color: var(--unity-color-font-link-default-on_light);

    &:hover {
        color: var(--unity-color-font-link-hover-on_light);
    }

    &:active {
        color: var(--unity-color-font-link-pressed-on_light);
    }

    &:visited {
        color: var(--unity-color-font-link-visited-on_light);
    }

    &.on_dark {
        color: var(--unity-color-font-link-default-on_dark);

        &:hover {
            color: var(--unity-color-font-link-hover-on_dark);
        }

        &:active {
            color: var(--unity-color-font-link-pressed-on_dark);
        }

        &:visited {
            color: var(--unity-color-font-link-visited-on_dark);
        }
    }
}

ul {
    margin: 0;
    padding: 0;
    list-style: inside disc;

    ul {
        padding-left: var(--grid-gutter-width);
        list-style: inside circle;
    }

    li {
        font-size: var(--font-size-xs);
    }
}

label {
    display: flex;
    align-items: center;
    gap: var(--unity-spacing-04);
    margin-bottom: calc($width_gutter_small/2);
    font-weight: var(--font-weight-xbold);
}

input,
.form-control {
    &:disabled {
        border-color: $gray-400;
    }
}

svg {
    width: 1em;
    height: 1em;

    &[class*="icon-handle"] {
        cursor: move;
    }

    &.icon-completing {
        animation: 3s linear infinite spinCW;
    }
}

.card {
    box-shadow: var(--unity-elevation-1);
}

/* Icon default colors */
.icon-warning {
    color: var(--bs-warning);
}

.icon-error-soft {
    color: #d68227;
}

.icon-error-hard {
    color: var(--bs-red);
}

[role="button"] {
    cursor: pointer;
}

hr {
    height: 1px;
    background-color: var(--unity-component-color-divider-standard);
    border: 0;
    opacity: 1;
}

$loader_size: 4rem;
.loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparentize($primary, 0.66);

    &::before {
        content: "";
        border: solid calc($loader_size/8) transparent;
        border-radius: 999px;
        width: $loader_size;
        height: $loader_size;
        transform-origin: center;
        animation: 3s linear infinite loader;
    }
}

.loading-inplace {
    display: flex;
    &::before {
        content: "";
        border: solid calc($loader_size/8) transparent;
        border-radius: 999px;
        width: 3rem;
        height: 3rem;
        transform-origin: center;
        animation: 3s linear infinite loader;
    }
}

// Loading for an inner div (not the entire screen)
.loading-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 999;
    width: 100%;
    height: 100%;
    background-color: transparentize($primary, 0.3);

    &::before {
        content: "";
        border: solid calc($loader_size/8) transparent;
        border-radius: 999px;
        width: $loader_size;
        height: $loader_size;
        transform-origin: center;
        animation: 3s linear infinite loader;
    }
}

.form-section {
    margin: $width_gutter_small * 4 0;
}

.form-group {
    margin-bottom: 1rem;
}

.form-group,
.configure--footer {
    button:not(.block) + button {
        margin-left: $width_gutter_small;
    }
}

.form-group {
    .alert,
    .help-block {
        margin-bottom: 0;
        margin-top: -0.2rem;
        max-width: 24rem;
        padding: 0.125rem 0.75rem;
        font-size: 85%;
        border-top: 0;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }
}

.row-add {
    background-color: transparent;
}

.row-remove {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.25em 0.5em;
    background-color: transparent;

    &button svg {
        margin: 0;
        width: 1.5rem;
        height: 2rem;
        color: $red;
    }
}

.form_optional {
    display: inline;
    margin-left: 0.25em;
    font-size: 90%;
    font-weight: var(--font-weight-base);
    font-style: italic;
    color: var(--unity-color-gray-060);
}

.close {
    svg {
        margin: $width_gutter_small * -1 0;
        width: 1.75rem;
        height: 1.75rem;
    }
}

.open > .dropdown-menu {
    display: block;
}

.btn-toggle {
    position: relative;
    margin-left: -0.125rem;
    width: 4rem;
    height: 2.5rem;
    background-color: $gray-300;

    &,
    &:after {
        border: 1px solid $gray-300;
        border-radius: 999px;
        box-shadow: inset 0 0 0.5rem -0.25rem rgba(0, 0, 0, 0.2);
    }

    &:after {
        content: "";
        position: absolute;
        top: 0.125rem;
        left: 0.125rem;
        width: calc(2.25rem - 1px);
        height: calc(2.25rem - 1px);
        background-color: $white;
    }

    &.on {
        background-color: var(--color-active);

        &:after {
            left: auto;
            right: 0.125rem;
        }
    }
}

.designer_pane {
    .dropdown-toggle {
        padding: 0 0.25rem;
        font-weight: $font-weight-bold;
        line-height: 1.25rem;
        background: transparent;
        border: 0;
    }
}

.workspace_tabs {
    background-color: white;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &:not(.form-group) {
        margin-bottom: 0;
    }

    .tab-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: auto;

        > .active {
            display: flex;
            flex-direction: column;
            position: relative;
        }
    }

    .tab-pane {
        flex: 1;
        border: 0;
        overflow: hidden;
    }
}

.workspace--checkboxes {
    display: flex;
    align-items: center;

    .form-check {
        margin-right: 1rem;
    }
}

/* Revisionable classes*/
.added-in-change {
    font-style: italic;

    .sle {
        padding-right: 0.15em;
    }
}

.added-in-another-change,
.locked-by-another-change {
    text-decoration: underline;
}

.deleted-in-another-change,
.deleted-in-change {
    text-decoration: line-through;
}

// Quill Editor
quill-editor {
    display: block !important;
  
    .ql-container .ql-editor {
      min-height: 12rem;
      max-height: 12rem;
      overflow: auto;
    }
  }

// No border quill editor
.quill-editor--no_border {
    .ql-container.ql-snow {
        border: none;
    }

    .ql-container .ql-editor {
        min-height: 0;
        padding-bottom: 0;
    }

    .ql-editor {
        padding: 0;
    }
}

.font-sm {
    font-size: var(--font-size-sm);
}

.font-xs {
    font-size: var(--font-size-xs);
}

.indicator-required {
    color: var(--unity-color-brand-magenta-base);
}
