[class^="icon-"], [class*=" icon-"] {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

/* ==========================================
Single-colored icons can be modified like so:
.icon-name {
  font-size: 32px;
  color: red;
}
========================================== */

.icon-search {
  width: 0.9285714285714285em;
}

.icon-doc-any {
  width: 0.8571428571428571em;
}

.icon-journal {
  width: 0.9302142857142857em;
}

.icon-arrow-left1 {
  width: 0.8928571428571428em;
}

.icon-arrow-right1 {
  width: 0.8214285714285714em;
}

.icon-undo {
  width: 0.8571428571428571em;
}

.icon-doc-text {
  width: 0.8571428571428571em;
}

.icon-money {
  width: 0.5697857142857142em;
}

.icon-asleep {
  width: 0.8236428571428571em;
}

.icon-current {
  width: 0.8571428571428571em;
}

.icon-doc-pdf {
  width: 0.8571428571428571em;
}

.icon-doc-word {
  width: 0.8571428571428571em;
}

.icon-doc-excel {
  width: 0.8571428571428571em;
}

.icon-doc-powerpoint {
  width: 0.8571428571428571em;
}

.icon-doc-image {
  width: 0.8571428571428571em;
}

.icon-doc-zip {
  width: 0.8571428571428571em;
}

.icon-doc-audio {
  width: 0.8571428571428571em;
}

.icon-doc-video {
  width: 0.8571428571428571em;
}

.icon-email {
  width: 0.8571428571428571em;
}

.icon-stop-hand {
  width: 0.9107142857142857em;
}

.icon-contact {
  width: 1.1428571428571428em;
}
