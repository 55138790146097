// unity design system variables
@import '@insurity/unity/css/_vars.scss';

// Kendo vars
$base-theme: Bootstrap;
$kendo-accent: $primary;

$kendo-component-border: $unity-color-border-container-default;

$kendo-grid-border: $color-primary-dark;

$kendo-grid-alt-bg: #ffffff;
$kendo-grid-alt-border: $color-primary-dark;

$kendo-grid-hovered-bg: #ffffff;

$kendo-grid-header-bg: $color-primary-dark;
$kendo-table-header-bg: $color-primary-dark;
$kendo-grid-header-text: #ffffff;
$kendo-table-header-text: #ffffff;
$kendo-grid-header-border: $color-primary-dark;
$kendo-table-header-border: $color-primary-dark;

$kendo-grid-gap: 1rem;

$kendo-cell-padding-x: 1rem;

$kendo-series-a: $unity-color-brand-blue-t30;
$kendo-series-b: $unity-color-brand-blue-t10;
$kendo-series-c: $unity-color-brand-blue-s20;
$kendo-series-d: $unity-color-brand-blue-base;
$kendo-series-e: $unity-color-brand-blue-s40;

$kendo-card-bg: var(--unity-color-surface-light-base);

$kendo-border-radius-lg: calc(var(--unity-radius-base) * 1.5);
$kendo-input-border-width: 1px;
$kendo-button-border-width: 1px;