// all fonts
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700|Open+Sans+Condensed:700');

// all icons
@import 'icomoon/style';

// all variables and mixins. theme and non-theme, custom and vendor
@import 'primer';

// all default bootstrap styles
@import 'bootstrap/scss/bootstrap';

// all default kendo styles
@import '@progress/kendo-theme-bootstrap/dist/all.scss';

// quill styles
@import '~quill/dist/quill.snow.css';

// Qlik styles
@import '/src/Core/Styles/qlike-themes/insurity-theme/insurity-theme-style.css';

// unity design system
@import '@insurity/unity/css/styles';

// all partials
@import 'partials/all';

.bg-primary { background-color: var(--bs-primary); }
.bg-primary-fade { background-color: var(--color-primary-fade); }
.bg-primary-darkish { background-color: var(--color-primary-darkish); }
.bg-primary-dark { background-color: var(--color-primary-dark); }
.bg-primary-darker { background-color: var(--color-primary-darker); }
.bg-primary-light { background-color: var(--color-primary-light); }
.bg-primary-lighter { background-color: var(--color-primary-lighter); }
.bg-primary-lightest { background-color: var(--color-primary-lightest); }
.bg-secondary { background-color: var(--bs-secondary); }
.bg-action { background-color: var(--color-action); }
.bg-active { background-color: var(--color-active); }
.bg-green { background-color: var(--bs-green); }
.bg-red { background-color: var(--bs-red); }
.bg-gray-100 { background-color: var(--unity-color-gray-010); }
.bg-gray-200 { background-color: var(--unity-color-gray-015); }

$color-active: var(--unity-color-brand-purple-t10);

:root {
    --color-active: #{$color-active};
}