﻿// major frame styles

html,
body {
    height: 100%;
    min-height: 100%;
    max-height: 100%;
}

/* Configure Area */

.container-fluid {
    height: 100%;
    padding: 2vw calc(2vw - var(--width_scrollbar)) 2vw 2vw;
    overflow: auto;
}

.configure--header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: calc(2rem + 2px);

    sm-search,
    kendo-multiselect,
    select {
        width: 12rem;
    }
}
.configure_header--left,
.configure_header--right {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.configure_header--left {
    > * {
        margin-right: $width_gutter_small;
    }
}
.configure_header--right {
    justify-content: flex-end;

    &:first-child {
        flex: 1;
    }

    > * {
        margin-left: calc( #{$width_gutter_small} * 2 );
    }
}

.configure--form,
.configure--list {
    margin-top: $width_gutter_small;
    margin-bottom: $width_gutter_small;
}

.configure--list {
    .icon-user {
        width: $width_gutter_small * 3;
        height: $width_gutter_small * 3;
    }
}

.configure--form {
    .form-group {
        max-width: 24rem;
    }
}

.configure--footer {
    flex: 1;
    padding: 1rem calc(2vw - var(--width_scrollbar)) 1rem 2vw;
    border-top: 1px solid var(--unity-color-gray-035);
}

// all designer spaces
.designer {
    display: flex;
    height: 100%;
    overflow: hidden;
}

.designer_pane {
    display: flex;
    flex-direction: column;
    width: $width_sidebar_left;
    min-width: 0;
    padding: 1.5rem 0 1.5rem 1.5rem;

    @include media-breakpoint-up(lg) {
        width: $width_designer_left;
    }
}

.designer_pane--header {
    margin-bottom: $width_gutter_small;
}

.designer_pane--content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.designer_workspace {
    flex: 1;
    display: flex;
    flex-direction: column;
}
