.qvt-sheet {
    background: #ECECEC !important;

}

.qv-object .qv-inner-object {
	background: #FFFFFF !important;
}

.qv-object-title-text {
	font-family: "ProximaNovaSemibold"!important;
}

.qv-object-subtitle {
	font-family: "ProximaNovaRegular"!important;
}

.qv-object-footnote {
	font-family: "ProximaNovaLight"!important;
	font-style: normal !important
}

@font-face {
    font-family: "ProximaNovaLight";
    src: url("./proximanova-light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal
}

@font-face {
    font-family: "ProximaNovaRegular";
    src: url("./proximanova-regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: "ProximaNovaSemibold";
    src: url("./proximanova-semibold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal
}

.sn-kpi-value {
    font-weight: 800;
}

.njs-cell-title {
    font: var(--unity-font-headline-small);
}

.Header-containerStyle {
    padding-bottom: 24px !important;
}

.njs-cell > div {
    padding: 0 !important;
}
